import React from "react"

import "../../styles/two-column-divider.scss"
import {GatsbyImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player/lazy"; // include sass styles

export default function TwoColumnDivider({ data }) {
  // console.log('two column divider ', data)

  return (
    <div className="two-column-divider-wrapper">
      <div className="two-column-divider">
        <div className="divided-text-content">
          {data?.dividers && data?.dividers?.map(divider => {
            return (
              <p className="description divider">{divider?.title}</p>
            )
          })}
        </div>
        <div className="image-container">
          {data?.video &&
          <ReactPlayer
            className='react-player'
            url={data?.video}
            width="100%"
            height="100%"
            light={true}
            controls={true}
            playsinline={true}
          />
          }
          {!data?.video &&
          <GatsbyImage alt={data?.image?.altText} image={data?.image?.localFile?.childImageSharp?.gatsbyImageData} />
          }
        </div>
      </div>
    </div>
  )
}
