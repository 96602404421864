import React from "react"

import "../../styles/divided-text.scss"

export default function DividedText({ data }) {
  return (
    <div className="divided-text-wrapper">
      <h2 className="title">{data?.title}</h2>
      {data?.dividerBoxes && data?.dividerBoxes?.map(divider => {
        return (
          <div className="divider">
            <h3>{divider?.title}</h3>
            <div className="description" dangerouslySetInnerHTML={{__html: divider?.content}}></div>
          </div>
        )
      })}
    </div>
  )
}
