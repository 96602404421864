import React, {useState} from "react"

import "../../styles/toggles-with-image.scss"

export default function TogglesWithImage({ data }) {
  // console.log('toggles with image ', data)

  var toggles = Array(data.toggles.length).fill(false);
  toggles[0] = true
  const [togglesMenu, setTogglesMenu] = useState(toggles)
  const [lastImage, setLastImage] = useState(0)

  function toggleMenu(index) {
    toggles.fill(false)
    toggles[index] = !togglesMenu[index]
    setLastImage(index)
    return setTogglesMenu([...toggles])
  }

  return (
    <div className="toggles-with-image-wrapper">
      <div className="toggle-with-image-desktop">

        <div className="toggles-wrapper">

          {data?.toggles && data?.toggles?.map((toggle, index) => {
            return (
              <div className={"toggle-item " + (togglesMenu[index] ? 'open' : '')}>
                <div className="toggle-header" onClick={() => toggleMenu(index)}>
                  <span className="toggle-icon">{togglesMenu[index] ? '-' : '+'}</span>
                  <h2 className="title white" dangerouslySetInnerHTML={{ __html: toggle?.title}}></h2>
                </div>
                <div className="toggle-content-wrapper">
                  <div className="toggle-content">
                    <p className="description white" dangerouslySetInnerHTML={{__html: toggle?.content}}></p>
                    <a className="button" href={toggle?.button?.url}>{toggle?.button?.title}</a>
                  </div>
                </div>
              </div>
            )
          })}

        </div>

        <div className="images-wrapper">
          <span className="white-flag"></span>
          {data?.toggles && data?.toggles?.map((toggle, index) => {
            return (
              <div className={"image-container " + (index === lastImage ? 'open' : '')}>
                <img src={toggle?.image?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src} alt={toggle?.title} />
              </div>
            )
          })}
        </div>

      </div>

      <div className="toggle-with-image-mobile">

        {data?.toggles && data?.toggles?.map((toggle,index) => {
          return (
            <div className={"toggle-item " + (togglesMenu[index] ? 'open' : '')}>

              <div className="toggle-header" onClick={() => toggleMenu(index)}>
                <span className="toggle-icon">{togglesMenu[index] ? '-' : '+'}</span>
                <h2 className="title white" dangerouslySetInnerHTML={{ __html: toggle?.title}}></h2>
              </div>
              <div className="toggle-content-wrapper">
                <div className="toggle-content">
                  <p className="description white" dangerouslySetInnerHTML={{__html: toggle?.content}}></p>
                  <a className="button" href={toggle?.button?.url}>{toggle?.button?.title}</a>
                </div>
                <div className="image-container">
                  <img src={toggle?.image?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src} alt={toggle?.title} />
                </div>
              </div>

            </div>
          )
        })}

      </div>

    </div>
  )
}
