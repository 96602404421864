import React, {useEffect, useState} from "react"

import "../../styles/boxed-slider.scss"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { EffectFade, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const isBrowser = typeof window !== "undefined"

export default function BoxedSlider({ data }) {
  // console.log('boxed slider ', data)

  const [width, setWidth] = useState((isBrowser) ? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth((isBrowser) ? window.innerWidth : 0);
  }
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
  }, []);

  const isMobile = width <= 980;
  // console.log('is mobile', isMobile)

  return (
    <div className="boxed-slider-wrapper">
      <div className="boxed-slider">
        <h2 className="title" dangerouslySetInnerHTML={{__html: data?.title}}></h2>
        <p className="description" dangerouslySetInnerHTML={{__html: data?.content}}></p>

        <Swiper
          spaceBetween={(isMobile ? 0 : 30)}
          slidesPerView={(isMobile ? 1 : 3)}
          modules={[Navigation, EffectFade]}
          navigation
          // effect="fade"
          // fadeEffect={{"crossFade": true}}
          className="slides"
        >
          {data?.slides && data?.slides?.map(slide => {
          return (
            <SwiperSlide className="slide">
              <div className="image-container">
                {slide?.link ? (
                    <Link to={slide?.link?.url} target={slide?.link?.target}>
                      <GatsbyImage alt={slide?.image?.altText} image={slide?.image?.localFile?.childImageSharp.gatsbyImageData} />
                    </Link>
                  ) : (
                    <GatsbyImage alt={slide?.image?.altText} image={slide?.image?.localFile?.childImageSharp.gatsbyImageData} />
                  )
                }
              </div>
              <div className="text-content">
                {slide?.logo &&
                <div className="logo-wrapper">
                  {slide?.link ? (
                      <Link to={slide?.link?.url} target={slide?.link?.target}>
                        <GatsbyImage alt={slide?.logo?.altText} image={slide?.logo?.localFile?.childImageSharp.gatsbyImageData} />
                      </Link>
                    ) : (
                      <GatsbyImage alt={slide?.logo?.altText} image={slide?.logo?.localFile?.childImageSharp.gatsbyImageData} />
                    )
                  }
                </div>
                }
                <h2 className="title">{slide?.title}</h2>
                <p className="description" dangerouslySetInnerHTML={{__html: slide?.content}}></p>
              </div>
            </SwiperSlide>
          )})
          }
        </Swiper>
      </div>
    </div>
  )
}
