import React from "react"

import "../../styles/meet-team-member.scss"
import {GatsbyImage} from "gatsby-plugin-image";

export default function MeetTeamMember({ data }) {
  // console.log('meet team member', data)

  return (
    <div className="meet-team-member-wrapper">
      <div className="meet-team-member">
        <div className="image-wrapper">
          <div className="image-container">
            <GatsbyImage
              alt={data?.image?.altText}
              image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
            />
          </div>
        </div>
        <div className="text-content-wrapper">
          <h2 className="title">{data?.title}</h2>
          <h4 className="subtitle">{data?.subtitle}</h4>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
        </div>
      </div>
    </div>
  )
}
