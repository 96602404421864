import React, {useState} from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/cta-boxes.scss"

export default function CTABoxes({ data }) {
  // console.log( data )
  var showContent = ['+', '+', '+']
  const [mobileContent, setMobileContent] = useState(showContent)

  function openBoxTabClick(event, index) {

    if (mobileContent[index] === '+') {
      showContent = mobileContent
      showContent[index] = '-'
      return setMobileContent([...showContent])
    }
    showContent = mobileContent
    showContent[index] = '+'
    // console.log(showContent)
    return setMobileContent([...showContent])
  }

  return (
    <div className="cta-boxes-wrapper">

      {data?.boxes && data?.boxes.map((box, index) => {
        return (
          <div className={"col one-of-" + data?.boxes.length}>
            <div className="preview-wrapper">
              <GatsbyImage image={box?.background?.localFile.childImageSharp.gatsbyImageData} alt={box?.title} />
              <div className="preview-content">
                <h2 className="title white" dangerouslySetInnerHTML={{ __html: box?.title}}></h2>
                <span className={mobileContent[index] === '+' ? 'plus' : 'minus'} onClick={event => openBoxTabClick(event, index)}></span>
              </div>
            </div>
            <div className="content-desktop-wrapper">
              <Link to={box?.link?.url} target={box?.link?.target}>
              <div className="content">
                  <h2 className="title white" dangerouslySetInnerHTML={{ __html: box?.title}}></h2>
                  <div className="description white" dangerouslySetInnerHTML={{ __html: box?.content }}></div>
                  {box?.link?.url &&
                  <button className="button white">Learn More</button>
                  }
              </div>
              </Link>
            </div>
            <div className={"content-mobile-wrapper " + (mobileContent[index] === '-' ? 'show' : '')}>
              <div className="content-mobile">
                <div className="description white" dangerouslySetInnerHTML={{ __html: box?.content }}></div>
                {box?.link?.url && (
                  <Link to={box?.link?.url} target={box?.link?.target} className="button white centralized">Learn More</Link>
                )}
              </div>
            </div>
          </div>
        )
      })}


    </div>
  )
}
