import React from "react"

import "../../styles/two-columns.scss"
import ReactPlayer from "react-player/lazy";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"; // include sass styles

export default function TwoColumns({ data }) {
  const videoCol = data?.col2.video ? 'col-video' : ''

  return (
    <div className={"two-columns-wrapper " + ( data?.darkTheme ? 'dark-theme' : '' )}>
      <div className="two-columns">
        <div className="col-1">
          {(data?.col1?.content || data?.col1?.title) &&
            <div className="text-content">
              {data?.col1?.subtitle &&
                <h3 className="subtitle">{data?.col1?.subtitle}</h3>
              }
              {data?.col1.title &&
                <h2 className="title" dangerouslySetInnerHTML={{__html: data?.col1?.title}}></h2>
              }
              <p className="description" dangerouslySetInnerHTML={{ __html: data?.col1?.content}}></p>
              {data?.col1?.button &&
                <Link
                  to={data?.col1?.button?.url}
                  target={data?.col1?.button?.target}
                  className={"button " + (data?.darkTheme ? 'outline-white' : '')}
                >
                  {data?.col1?.button?.title}
                </Link>
              }
            </div>
          }
          {!data?.col1?.content && data?.col1?.video &&
            <div className="video-wrapper">
              <ReactPlayer
                className='react-player'
                url={data?.col1?.video}
                width="100%"
                height="56.3%"
                light={true}
                controls={true}
                playsinline={true}
              />
            </div>
          }
          {!data?.col1?.content && !data?.col1?.video && data?.col1?.image &&
            <div className="image-wrapper">
              <GatsbyImage alt="column-image" image={data?.col1?.image?.localFile.childImageSharp.gatsbyImageData} />
            </div>
          }
        </div>
        <div className={`col-2 ${videoCol}`}>
          {(data?.col2?.content || data?.col2?.title) &&
          <div className="text-content">
            {data?.col2?.subtitle &&
              <h3 className="subtitle">{data?.col2?.subtitle}</h3>
            }
            {data?.col2.title &&
              <h2 className="title" dangerouslySetInnerHTML={{__html: data?.col2?.title}}></h2>
            }
            <p className="description" dangerouslySetInnerHTML={{ __html: data?.col2.content}}></p>
            {data?.col2?.button &&
              <Link
                to={data?.col2?.button?.url}
                target={data?.col2?.button?.target}
                className={"button " + (data?.darkTheme ? 'outline-white' : '')}
              >
                {data?.col2?.button?.title}
              </Link>
            }
          </div>
          }
          {!data?.col2?.content && data?.col2.video &&
          <div className="video-wrapper">
            <ReactPlayer
              className='react-player'
              url={data?.col2.video}
              width="100%"
              height="100%"
              light={true}
              controls={true}
              playsinline={true}
            />
          </div>
          }
          {!data?.col2?.content && !data?.col2?.video && data?.col2?.image &&
          <div className="image-wrapper">
            <GatsbyImage alt="column-image" image={data?.col2?.image?.localFile.childImageSharp.gatsbyImageData} />
          </div>
          }
        </div>
      </div>
    </div>
  )
}
