import React, { useLayoutEffect } from "react"
import ReactPlayer from "react-player/lazy"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/banner.scss"

export default function Banner({ data, slug, location }) {
  useLayoutEffect(() => {
    setTimeout(function() {
      const player = document.querySelector('.react-player__preview')

      if (player) {
        player.click()
      }
    }, 3000)
  }, [])

  const videoUrl = (data?.directVideoLink) ? data?.directVideoLink : data?.video
  const playerClass = (data?.directVideoLink) ? 'no-frame' : ''

  return (
    <div className="banner-wrapper">
      <div className={'background-banner ' + playerClass}>
        <div className="overlay"></div>
        {(data?.video || data?.directVideoLink) &&
          <ReactPlayer
          className='react-player'
          loop={true}
          volume="0"
          muted={true}
          playing={true}
          playsinline={true}
          url={videoUrl}
          width="100%"
          height="100%"
          light={true}
          />
        }
        {!data?.video && data?.background &&
          <div className="image-container">
            <GatsbyImage image={data?.background.localFile.childImageSharp.gatsbyImageData} alt={data?.background?.altText} />

            <div className="show-mobile">
              {data?.mobileBackground.localFile.childImageSharp.gatsbyImageData ? (
                <GatsbyImage image={data?.mobileBackground.localFile.childImageSharp.gatsbyImageData} alt={data?.mobileBackground?.altText} />
              ) : (
                <GatsbyImage image={data?.background.localFile.childImageSharp.gatsbyImageData} alt={data?.background?.altText} />
              )}
            </div>
          </div>
        }
        <div className={`banner-text banner-${slug}`}>
          <h4 className="subtitle white">{data?.subtitle}</h4>
          <h2 className="title white">{data?.title}</h2>
          <div className="description white" dangerouslySetInnerHTML={{__html: data?.content}}></div>

          {data?.buttons && (
            <Link to={data?.buttons[0]?.button?.url} className="button centralized">{data?.buttons[0]?.button?.title}</Link>
          )}
        </div>
      </div>
      <div className="banner-description-mobile">
        <div className="description white" dangerouslySetInnerHTML={{__html: data?.content}}></div>

        {data?.buttons && (
          <Link to={data?.buttons[0]?.button?.url} className="button centralized">{data?.buttons[0]?.button?.title}</Link>
        )}
      </div>
    </div>
  )
}
