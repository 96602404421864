import React from "react"
import { Link } from "gatsby"

import "../../styles/comparison-tables.scss" // include sass styles

export default function ComparisonTables({ data }) {
  // console.log('comparison tables ', data)
  var lastCol = data?.columns?.length - 1

  return (
    <div className="comparison-tables-wrapper">
      <div className="header-wrapper">
        <h4 className="subtitle">{data?.subtitle}</h4>
        <h2 className="title">{data?.title}</h2>
      </div>
      <div className="table-wrapper">
        <table>
          <tr>
            <th></th>
            <th className="le-column-top">Leading Edge Flight School</th>
            <th>Other Flight Schools</th>
          </tr>
          {data?.columns && data?.columns?.map(column => {
            return (
              <tr className="table-cols">
                <td>{column?.heading}</td>
                {column?.cols && column?.cols.map((col, index) => {
                  return (
                    <td className={"table-values " + (index === 0 ? 'le-table-values' : '')}>{col?.col}</td>
                  )
                })}
              </tr>
            )
          })}
          {data?.columns[lastCol]?.button &&
            <tr>
              <td></td>
              {data?.columns[lastCol] &&
                <td className="button-td">
                  <Link className="button" to={data?.columns[lastCol]?.button?.url} target={data?.columns[lastCol]?.button?.target}>
                    {data?.columns[lastCol]?.button?.title}
                  </Link>
                </td>
              }
            </tr>
          }
        </table>
      </div>
    </div>
  )
}
