import React, {useState} from "react"

import "../../styles/toggles.scss" // include sass styles

export default function Toggles({ data }) {
  // console.log('toggles', data)

  var toggles = Array(data.toggles.length).fill(false);
  const [togglesMenu, setTogglesMenu] = useState(toggles)

  function toggleMenu(index) {
    toggles.fill(false)
    toggles[index] = !togglesMenu[index]
    return setTogglesMenu([...toggles])
  }

  return (
    <div className="toggles-wrapper">
      <div className="toggles">
        {data?.toggles && data?.toggles?.map((toggle, index) => {
          return (
            <div className={"toggle " + (togglesMenu[index] ? 'open' : '')}>
              <div className="toggle-header" onClick={() => toggleMenu(index)}>
                <span className="toggle-icon">{togglesMenu[index] ? '-' : '+'}</span>
                <h2 className="title" dangerouslySetInnerHTML={{ __html: toggle?.title}}></h2>
              </div>
              <div className="toggle-content-wrapper">
                <div className="toggle-content">
                  <p className="description" dangerouslySetInnerHTML={{__html: toggle?.content}}></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
