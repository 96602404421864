import React from "react"
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player/lazy";

import "../../styles/image-right.scss"

export default function ImageRight({ data }) {
  return (
    <div className="image-right-wrapper" id={data?.anchorLink}>
      <div className="content-wrapper">
        <div className={"content " + (data?.logo ? 'with-logo' : '')}>
          <GatsbyImage alt={data?.logo?.altText} image={data?.logo?.localFile?.childImageSharp.gatsbyImageData} />
          <h4 className="subtitle">{data?.subtitle}</h4>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: data?.title}}></h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
          <Link to={data?.button?.url} target={data?.button?.target} className="button">{data.button?.title}</Link>
        </div>
      </div>
      <div className="image-wrapper">
        <GatsbyImage alt="image" image={data?.image?.localFile.childImageSharp.gatsbyImageData} />
        {!data?.image && data?.video &&
        <ReactPlayer
          className='react-player'
          url={data?.video}
          width="100%"
          height="100%"
          light={true}
          controls={true}
          playsinline={true}
        />
        }
      </div>
    </div>
  )
}
