import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/grid.scss"
import "../../styles/instructors.scss"

export default function Instructors({ data }) {
  return (
    <div className="instructors-wrapper">
      <div className="container">
        {data?.title ? (
          <h2 className="title">{data?.title}</h2>
        ) : null}

        <div className="row">
          {data?.instructors && data?.instructors?.map(instructor => {
            return (
              <div className="col instructor">
                <GatsbyImage image={instructor?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData} alt={instructor?.featuredImage?.node?.altText} />
                <h3>{instructor?.title}</h3>
                <div className="subtitle" dangerouslySetInnerHTML={{ __html: instructor?.content}}></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
