import React from "react"
import { Link } from "gatsby";
import ReactPlayer from "react-player/lazy";
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/boxed-two-columns.scss"

export default function BoxedTwoColumns({ data }) {
  // console.log('boxed two columns ', data)

  return (
    <div className="boxed-two-columns-wrapper" id={data?.anchorLink}>
      <div className={`boxed-two-columns ${data?.col1?.logo ? 'with-logo' : ''}`}>
        <div className="col-1">
          {(data?.col1?.content || data?.col1?.title) &&
          <div className="text-content">
            {data?.col1?.logo &&
              <div className="logo-wrapper">
                <img
                  src={data?.col1?.logo?.localFile?.publicURL}
                  alt={data?.col1?.logo?.altText}
                  loading="lazy"
                />
              </div>
            }
            {data?.col1?.subtitle &&
            <h4 className="subtitle">{data?.col1?.subtitle}</h4>
            }
            {data?.col1?.title &&
            <h2 className="title" dangerouslySetInnerHTML={{ __html: data?.col1?.title}}></h2>
            }
            {data?.col1?.content && (
              <div className="description" dangerouslySetInnerHTML={{ __html: data?.col1?.content}}></div>
            )}
            {data?.col1?.button &&
            <Link
              to={data?.col1?.button?.url}
              target={data?.col1?.button?.target}
              className="button"
            >
              {data?.col1?.button?.title}
            </Link>
            }
          </div>
          }
          {!data?.col1?.content && data?.col1?.video &&
          <div className="video-wrapper">
            <ReactPlayer
              className='react-player'
              url={data?.col1?.video}
              width="100%"
              height="100%"
              light={true}
              controls={true}
              playsinline={true}
            />
          </div>
          }
          {!data?.col1?.content && !data?.col1?.video && data?.col1?.image &&
          <div className="image-wrapper">
            <GatsbyImage alt="column-image" image={data?.col1?.image?.localFile.childImageSharp.gatsbyImageData} />
          </div>
          }
        </div>
        <div className="col-2">
          {(data?.col2?.content || data?.col2?.title) &&
          <div className="text-content">
            {data?.col1?.logo &&
            <div className="logo-empty-wrapper"></div>
            }
            {data?.col2.title &&
            <h2 className="title" dangerouslySetInnerHTML={{__html: data?.col2?.title}}></h2>
            }
            {data?.col2.content && (
              <div className="description" dangerouslySetInnerHTML={{ __html: data?.col2.content}}></div>
            )}
            {data?.col2?.button &&
            <Link
              to={data?.col2?.button?.url}
              target={data?.col2?.button?.target}
              className="button"
            >
              {data?.col2?.button?.title}
            </Link>
            }
            {data?.col1?.button &&
              <div className="show-mobile">
                <Link
                  to={data?.col1?.button?.url}
                  target={data?.col1?.button?.target}
                  className="button"
                >
                  {data?.col1?.button?.title}
                </Link>
              </div>
            }
          </div>
          }
          {!data?.col2?.content && data?.col2?.video &&
          <div className="video-wrapper">
            <ReactPlayer
              className='react-player'
              url={data?.col2?.video}
              width="100%"
              height="100%"
              light={true}
              controls={true}
              playsinline={true}
            />
          </div>
          }
          {!data?.col2?.content && !data?.col2?.video && data?.col2?.image &&
          <div className="image-wrapper">
            <GatsbyImage alt="column-image" image={data?.col2?.image?.localFile.childImageSharp.gatsbyImageData} />
          </div>
          }

        </div>
      </div>
    </div>
  )
}
