import React, { useLayoutEffect } from "react"
import { withPrefix, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js"

// import "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.css"
import "../../styles/text-content.scss" // include sass styles

export default function TextContent({ data }) {
  useLayoutEffect(() => {
    setTimeout(function() {
      var co = document.createElement("script");
      co.type = "text/javascript";
      co.async = true;
      co.defer = true;
      co.src = "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.umd.js";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(co, s);
    }, 3000)
  }, [])

  return (
    <div className="text-content-wrapper" style={{'backgroundColor':data?.backgroundColor}}>
      <div className="text-content">
        {data?.subtitle ? (
          <h4 className="subtitle">{data?.subtitle}</h4>
        ) : null}

        {data?.title ? (
          <h2 className="title">{data?.title}</h2>
        ) : null}

        {data?.content ? (
          <div className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
        ) : null}

        {data?.image && (
          <div className="image-wrap">
            {data?.pdf?.guid ? (
              <a data-fancybox data-type="iframe" data-src={data?.pdf?.guid} href="javascript:;">
                <img src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
              </a>
            ) : (
              <GatsbyImage image={data?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={data?.image?.altText} />
            )}
          </div>
        )}

        {data?.buttons && (
          <div className="button-wrap">
            {data?.buttons?.map(button => {
              if (button?.button?.url.includes('.pdf')) {
                return (
                  <a data-fancybox data-type="iframe" data-src={data?.buttons[0]?.button?.url} href="javascript:;" className="button centralized">
                    {button.button?.title}
                  </a>
                )
              } else {
                return (
                  <Link to={button?.button?.url} target={button?.button?.target} className="button centralized">{button.button?.title}</Link>
                )
              }
            })}
          </div>
        )}
      </div>
    </div>
  )
}
