import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Form from "../../components/form"

import "../../styles/contact-form.scss"

export default function ContactForm({ data }) {
  // console.log('contact form ', data)
  const form = useStaticQuery(graphql`
    query formQuery {
      wpGfForm(databaseId: { eq: 1 }) {
        ...GravityFormFields
      }
    }
  `);
// console.log(data, 'contact')
  return (
    <div className="contact-form-wrapper">
      <div className="form-wrapper">
        {data?.title ? (
          <h2 className="title">{data?.title}</h2>
        ) : null}

        <Form data={form} />
      </div>
      <div className="contact-address-wrapper">
        {data?.contact?.title ? (
          <h2 className="title">{data?.contact?.title}</h2>
        ) : null}

        {data?.contact?.content ? (
          <div dangerouslySetInnerHTML={{__html: data?.contact?.content}}></div>
        ) : null}

        {data?.contact?.map && (
          <iframe
            className="contact-us-map"
            src={data?.contact?.map}
            width="600" height="350" style={{border: 0}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        )}

        {data?.contact?.map2 && (
          <iframe
            className="contact-us-map"
            src={data?.contact?.map2}
            width="600" height="350" style={{border: 0}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        )}
      </div>
    </div>
  )
}
