import React from "react"

import "../../styles/positions.scss" // include sass styles

export default function Positions({ data }) {
  // console.log('positions', data)

  return (
    <div className="positions-wrapper">
      <div className="positions">
        <h2 className="title">{data?.title}</h2>
        <div className="position-items">
          {data?.positions && data?.positions?.map(position => {
            return (
              <h2 className="title position">
                <a href={position?.link?.url} target={position?.link?.target}>{position?.link?.title}</a>
              </h2>
            )
          })}
        </div>
      </div>
    </div>
  )
}
