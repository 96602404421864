import React from "react"

import "../../styles/grid.scss"
import "../../styles/steps.scss"

export default function Steps({ data }) {
  return (
    <div className="steps-wrapper">
      <div className="container">
        <h2 className="title">{data?.title}</h2>
        <div className="row">
          {data?.steps && data?.steps?.map(step => {
            return (
              <div className="col">
                <h3>{step?.title}</h3>
                <div className="description" dangerouslySetInnerHTML={{__html: step?.content}}></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
