import React, { useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, FreeMode, Navigation} from "swiper";

import "../../styles/logos.scss"

export default function Logos({ data }) {
  useEffect(() => {
    document.querySelector('.swiper-button-next').addEventListener('mouseover', function(e) {
      e.currentTarget.click()
    })

    document.querySelector('.swiper-button-prev').addEventListener('mouseover', function(e) {
      e.currentTarget.click()
    })
  });

  const slidesPerView = (data?.logos?.length >= 6) ? 6 : data?.logos?.length

  return (
    <div className="logos-wrapper">
      <h2 className="title">Aviation Partners</h2>
      <div className="logos">
        <Swiper
          modules={[Autoplay, FreeMode, Navigation]}
          // autoplay={{delay: 2500}}
          navigation={{enabled: true}}
          // freeMode={{enabled: true}}
          // spaceBetween={30}
          slidesPerView={slidesPerView}
          speed={1000}
          // centeredSlides={true}
          // loop={false}
        >
        {data?.logos && data?.logos?.map(logo => {
          return (
            <SwiperSlide>
              <Link to={logo?.link?.url} target={logo?.link?.target} className="logo-wrapper">
                <GatsbyImage alt="logo" image={logo?.logo?.localFile.childImageSharp.gatsbyImageData} />
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
      </div>
    </div>
  )
}
